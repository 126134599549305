import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-c00e8e70"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "article-page--wrapper",
  itemscope: "",
  itemtype: "https://schema.org/NewsArticle"
};
const _hoisted_2 = {
  class: "article-page--header"
};
const _hoisted_3 = {
  class: "article-page--header--image"
};
const _hoisted_4 = ["src", "alt"];
const _hoisted_5 = {
  class: "article-page--header--title"
};
const _hoisted_6 = {
  itemprop: "headline"
};
const _hoisted_7 = {
  class: "article-page--body"
};
const _hoisted_8 = {
  class: "cta"
};
const _hoisted_9 = ["innerHTML"];
const _hoisted_10 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SearchOffers = _resolveComponent("SearchOffers");

  const _component_el_divider = _resolveComponent("el-divider");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
    itemprop: "image",
    src: $options.imageUrl($setup.article.image),
    alt: $setup.article.title
  }, null, 8, _hoisted_4)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("h1", _hoisted_6, _toDisplayString($setup.article.title), 1)])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_SearchOffers)]), _createElementVNode("div", null, [_createElementVNode("h2", {
    itemprop: "abstract",
    class: "article-page--body--sub-title",
    innerHTML: $setup.article.subTitle
  }, null, 8, _hoisted_9), _createVNode(_component_el_divider), _createElementVNode("div", {
    itemprop: "articleBody",
    innerHTML: $setup.article.content
  }, null, 8, _hoisted_10)])])]);
}