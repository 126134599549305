import { useRoute } from 'vue-router';
import SearchOffers from '@/fragments/SearchOffers';
import articles from '@/content/Articles/index';
export default {
  name: 'ArticlePage',
  components: {
    SearchOffers
  },

  setup() {
    const route = useRoute();
    var article = articles;
    article = articles.filter(article => article.title === route.name);
    return {
      article: article[0]
    };
  },

  methods: {
    imageUrl(imageUrl) {
      return require('@/assets/articles/' + imageUrl);
    }

  }
};